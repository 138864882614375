<template>

  <div class="consignment-note">

    <div
      v-if="!pageLoad"
      class="page-loader"
    >
      <div
        class="spinner-border text-primary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="page-title d-flex py-2 justify-content-between">

      <h1 class="h4 mb-0">
        Товарная накладная <span v-if="currentConsignmentNote">№ {{ currentConsignmentNote.number }} от {{ currentConsignmentNote.date | parseDate }}</span>
      </h1>

      <div class="d-flex align-items-center">
        <BButton
          class="ml-3"
          variant="danger"
        >
          Экспорт
        </BButton>
        <BButton
          v-if="isContractor && currentConsignmentNote.is_approved === false"
          class="ml-3"
          variant="outline-primary"
          :to="`${$route.fullPath}/edit`"
        >Редактировать
        </BButton>
      </div>

    </div>

    <b-tabs
      v-model="tabIndex"
      card
    >

      <b-tab title="Информация">

        <div v-if="currentConsignmentNote.id">
          <b-row>

            <b-col cols="6">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px;"
                >№ накладной
                </div>
                <div class="px-2 py-1 flex-grow-1">{{ currentConsignmentNote.number }}</div>
              </div>
            </b-col>

            <b-col cols="6">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px;"
                >Дата
                </div>
                <div class="px-2 py-1 flex-grow-1">{{ currentConsignmentNote.date | parseDate }}</div>
              </div>
            </b-col>

          </b-row>

          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px;"
            >Филиал заказчик</div>
            <div class="px-2 py-1 flex-grow-1">{{ getOrganization(currentConsignmentNote.organization_id).name }}</div>
          </div>

          <b-row>

            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px;"
                >Поставщик
                </div>
                <div class="px-2 py-1 flex-grow-1">{{ currentConsignmentNote.provider.name }}</div>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px;"
                >№ договора поставки
                </div>
                <div class="px-2 py-1 flex-grow-1">{{ currentConsignmentNote && currentConsignmentNote.provider_contract && currentConsignmentNote.provider_contract.number }}</div>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px;"
                >Дата договора поставки
                </div>
                <div class="px-2 py-1 flex-grow-1">{{ ( currentConsignmentNote && currentConsignmentNote.provider_contract && currentConsignmentNote.provider_contract.date ) | parseDate }}</div>
              </div>
            </b-col>

          </b-row>

          <b-row>

            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px;"
                >Подрядчик
                </div>
                <div class="px-2 py-1 flex-grow-1">{{ currentConsignmentNote.contractor.name }}</div>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px;"
                >№ договора подряда
                </div>
                <div class="px-2 py-1 flex-grow-1">{{ currentConsignmentNote.work_agreement ? currentConsignmentNote.work_agreement.number : '' }}</div>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px;"
                >Дата договора подряда
                </div>
                <div class="px-2 py-1 flex-grow-1">{{ (currentConsignmentNote.work_agreement ? currentConsignmentNote.work_agreement.date : '') | parseDate }}</div>
              </div>
            </b-col>

          </b-row>

          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px;"
            >Название объекта</div>
            <div class="px-2 py-1 flex-grow-1">{{ currentConsignmentNote && currentConsignmentNote.object && currentConsignmentNote.object.name }}
            </div>
          </div>

          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px;"
            >Название подобъекта</div>
            <div class="px-2 py-1 flex-grow-1">{{ getSubObject(currentConsignmentNote.customer_sub_object_id).name }}</div>
          </div>

          <b-row>

            <b-col cols="6">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px;"
                >Ответственный
                </div>
                <div class="px-2 py-1 flex-grow-1">{{ currentConsignmentNote.responsible_full_name }}</div>
              </div>
            </b-col>

            <b-col cols="6">
              <div class="d-flex table-bordered mb-1">
                <div
                  class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                  style="width: 250px;"
                >Телефон
                </div>
                <div class="px-2 py-1 flex-grow-1">{{ currentConsignmentNote.responsible_phone | VMask('+# (###) ###-##-##') }}</div>
              </div>
            </b-col>
          </b-row>

          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px;"
            >Комментарий</div>
            <div class="px-2 py-1 flex-grow-1">{{ currentConsignmentNote.comment }}</div>
          </div>
        </div>

      </b-tab>

      <b-tab title="Позиции">

        <TableFilter
          :columns="currentConsignmentNotePositionsFields"
          uniqueKey="table-2"
          @update-selected-columns="columns = $event"
          @search="filter = $event"
          @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
          @clear="clearFilterData"
        />

        <DefaultTable
          :data="currentConsignmentNote.positions"
          :fields="currentConsignmentNotePositionsFieldsFiltered"
          :filter="filter"
          emptyText="Нет позиций"
          :withIterator="true"
          :loading="positionsLoading"
          :noLocalSorting="true"
          :withSpecifiedTotal="getPositionsTotal"
          @sort-change="sorting"
          @pagination-change="paginationChange"
          @per-page-change="perPageChange"
        />

      </b-tab>

    </b-tabs>

    <FilterSidebar
      id="filter"
      v-model="filterData"
      @apply="updatePositionsWithFilters(true)"
    />

  </div>

</template>

<script>
import { mapState, mapGetters } from "vuex";
import TableFilter from "@/components/TableFilter";
import DefaultTable from "@/components/Tables/Default";
import FilterSidebar from "@/components/FilterSidebar";

function initialFilterData() {
  return {
    order_number: "",
    mnemocode: "",
    nomenclature_name: "",
    country: "",
    cargo_custom_declaration: "",
    declaration: "",
  };
}

export default {
  name: "ConsignmentNote",
  components: {
    TableFilter,
    DefaultTable,
    FilterSidebar,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    ...mapState({
      currentConsignmentNote: (state) =>
        state.consignmentNotes.currentConsignmentNote,
    }),
    ...mapGetters([
      "getOrganization",
      "getObject",
      "getSubObject",
      "isProvider",
      "isContractor",
    ]),
    currentConsignmentNotePositionsFieldsFiltered() {
      let res = [];
      this.currentConsignmentNotePositionsFields.forEach((item) => {
        if (this.columns.filter((col) => col === item.label).length) {
          res.push(item);
        }
      });
      return res.length > 0 ? res : this.currentConsignmentNotePositionsFields;
    },
    breadcrumbs() {
      return [
        { title: "Главная", link: "/" },
        { title: "Заказ ПО", link: "/" },
        {
          title: "Перечень товарных накладных по подряду",
          link: "/consignment-notes",
        },
        {
          title: `Товарная накладная № ${
            this.currentConsignmentNote.number
          } от ${this.$options.filters.parseDate(
            this.currentConsignmentNote.date
          )}`,
        },
      ];
    },
    getPositionsTotal() {
      const withoutVat =
        this.currentConsignmentNote.positions_sum_amount_without_vat;
      const withVat = this.currentConsignmentNote.positions_sum_amount_with_vat;
      return `Всего сумма по документу: без НДС ${withoutVat} руб.; с НДС ${withVat} руб.`;
    },
  },
  data: () => ({
    currentConsignmentNotePositionsFields: [
      {
        key: "order_number",
        sortable: true,
        label: "№ заказа",
        full: "Номер заказа",
        openDefault: true,
      },
      {
        key: "order_date",
        sortable: false,
        label: "Дата заказа",
        full: "Дата заказа",
        openDefault: true,
      },
      {
        key: "mnemocode",
        sortable: true,
        label: "Мнемокод",
        full: "Мнемокод",
        openDefault: true,
      },
      {
        key: "nomenclature_name",
        sortable: true,
        label: "Номенклатура",
        full: "Номенклатура",
        openDefault: true,
      },
      {
        key: "count",
        sortable: true,
        label: "Количество",
        full: "Количество",
        openDefault: true,
      },
      {
        key: "price_without_vat",
        sortable: true,
        label: "Цена ₽ (без НДС)",
        full: "Цена ₽ (без НДС)",
        openDefault: true,
      },
      {
        key: "amount_without_vat",
        sortable: true,
        label: "Сумма ₽ (без НДС)",
        full: "Сумма ₽ (без НДС)",
        openDefault: true,
      },
      {
        key: "vat_rate",
        sortable: true,
        label: "Ставка НДС, %",
        full: "Ставка НДС, %",
        openDefault: true,
      },
      {
        key: "amount_with_vat",
        sortable: true,
        label: "Сумма ₽ (с НДС)",
        full: "Сумма ₽ (с НДС)",
        openDefault: true,
      },
      {
        key: "country_name",
        sortable: true,
        label: "Страна",
        full: "Страна происхождения",
        openDefault: true,
      },
      {
        key: "cargo_custom_declaration",
        sortable: true,
        label: "ГДТ",
        full: "ГДТ",
        openDefault: true,
      },
      {
        key: "declaration",
        sortable: true,
        label: "Декларация",
        full: "Декларация",
        openDefault: true,
      },
    ],
    tabIndex: 0,
    columns: [],
    filter: "",
    filterData: initialFilterData(),
    pageLoad: true,
    positionsLoading: false,
    sortingData: {
      sort_field: "",
      sort_order: "",
      page: "",
      per_page: 25,
    },
  }),
  methods: {
    async loadPage() {
      this.pageLoad = false;
      await Promise.all([
        this.$store.dispatch("getConsignment", this.id),
        this.$store.dispatch("getConsignmentPositions", { id: this.id }),
      ]);
      this.currentConsignmentNotePositionsFields.forEach((item) => {
        if (item.openDefault) {
          this.columns.push(item.label);
        }
      });
      this.$store.dispatch("checkNotification", {
        entity: "consignments",
        id: this.id,
      });
      this.pageLoad = true;
    },
    async updatePositionsWithFilters(clearSort) {
      this.positionsLoading = true;
      if (clearSort) {
        this.sortingData.sort_field = "";
        this.sortingData.sort_order = "";
        this.sortingData.page = "";
      }
      await this.$store.dispatch("getConsignmentPositions", {
        id: this.id,
        filters: {
          ...this.sortingData,
          ...this.filterData,
        },
      });
      this.positionsLoading = false;
    },
    clearFilterData() {
      this.filterData = initialFilterData();
      this.updatePositionsWithFilters(true);
    },
    sorting(event) {
      this.sortingData.sort_field = event.sortBy;
      this.sortingData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updatePositionsWithFilters();
    },
    paginationChange(page) {
      this.sortingData.page = page;
      this.updatePositionsWithFilters();
    },
    perPageChange(perPage) {
      this.sortingData.page = "";
      this.sortingData.per_page = perPage;
      this.updatePositionsWithFilters();
    },
  },
  async mounted() {
    await this.loadPage();
  },
};
</script>

<style scoped>
</style>
